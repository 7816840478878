<app-titulo titulo="Parceiros &" subtitulo="Patrocinadores"></app-titulo>
<h4>Os descontos dos patrocinadores são exclusivos para quem possui o <strong>Cartão do Clube</strong></h4>
<div class="header">
    <div class="search">
        <mat-icon>search</mat-icon>
        <input placeholder="Pesquisar patrocinador" [(ngModel)]="filter">
    </div>
</div>
<div class="patrocinadores">
    <div class="patrocinador" *ngFor="let patrocinador of patrocinadores | async | filterBy: 'nome':filter"
        (click)="select(patrocinador)" [ngClass]="{'active': selected === patrocinador }">
        <div class="image">
            <img *ngIf="patrocinador.logo; else nopic" [src]="patrocinador.logo" alt="Imagem de {{patrocinador.nome}}"
                title="Imagem de {{patrocinador.nome}}">
            <ng-template #nopic>
                <div class="sem-imagem">
                    Sem imagem
                </div>
            </ng-template>
        </div>
        <label for="nome">{{ patrocinador.nome }}</label>
        <div class="beneficios">
            <label>Benefícios</label>
            <div class="beneficio" *ngFor="let beneficio of patrocinador.beneficios">
                <label for="beneficio">{{beneficio.nome}}</label>
                {{beneficio.descricao}}
            </div>
        </div>
    </div>
</div>
<app-loader [loading]="loading"></app-loader>