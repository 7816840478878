import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { LoaderComponent } from '../shared/loader/loader.component';
import { SideContainerComponent } from '../side-container/side-container.component';
import { TituloComponent } from '../titulo/titulo.component';
import { FilterByPipe } from './pipes/filter-by.pipe';
import { ResourceSanitize } from './pipes/sanitize.pipe';


@NgModule({
  declarations: [
    LoaderComponent,
    TituloComponent,
    FilterByPipe,
    SideContainerComponent,
    ResourceSanitize
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    MatIconModule,
  ],
  exports: [
    LoaderComponent,
    TituloComponent,
    CommonModule,
    MatTabsModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    MatIconModule,
    SideContainerComponent,
    FilterByPipe,
    ResourceSanitize
  ]
})
export class SharedModule { }
