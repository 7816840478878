import {
  AfterContentInit, Component, ElementRef, EventEmitter, HostBinding, Input,
  OnChanges, OnDestroy, OnInit,
  Output, SimpleChanges
} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-side-container',
  templateUrl: './side-container.component.html',
  styleUrls: ['./side-container.component.scss']
})
export class SideContainerComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy {

  @Input()
  @HostBinding('class.active')
  active: boolean;

  overlay: HTMLDivElement;

  @Output()
  cancel: EventEmitter<any> = new EventEmitter<any>();

  activeSubject: Subject<boolean> = new Subject();

  constructor(private el: ElementRef<HTMLElement>) {

  }

  ngOnInit(): void {
    this.overlay = this.el.nativeElement.querySelector('.overlay');

    this.activeSubject
      .subscribe(active => {
        if (this.overlay && this.active) {
          const scrollHeight = document.querySelector('body').scrollHeight;
          const height = `${scrollHeight / 10}rem`;

          this.el.nativeElement.style.height = height;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.activeSubject.next(changes.active.currentValue);
  }

  ngOnDestroy(): void { }

  ngAfterContentInit(): void {
    if (!this.cancel.observers.length) {
      throw Error('Função cancel não informada');
    }
  }

  onCancel(event: any): void {
    this.cancel.emit(event);
    this.active = false;
  }

}
