import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EventosComponent } from './eventos.component';


const routes: Routes = [
  {
    path: 'eventos', component: EventosComponent, data: {
      seo: {
        title: 'Eventos',
        description: 'Eventos do Clube'
      }
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EventosRoutingModule { }
