import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Patrocinador } from '../shared/model';

@Injectable({
  providedIn: 'root',
})
export class PatrocinadoresService {

  patrocinador: Patrocinador;
  total: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor(private http: HttpClient) {
    const subscription = this.getAll()
      .subscribe((elements) => {
        this.total.next(Object.keys(elements).length);
      }, (error) => {
      });
  }

  getAll(): Observable<Patrocinador[]> {
    return this.http
      .get<Patrocinador[]>('https://jimnyclubece-1734d.firebaseio.com/patrocinadores.json');
  }

  getById(id: string): Observable<Patrocinador> {
    return this.http
      .get<Patrocinador>(`https://jimnyclubece-1734d.firebaseio.com/patrocinadores.json?orderBy=\"$key\"&startAt=\"${id}\"&endAt=\"${id}\"`)
      .pipe(
        map((e) => {
          const key = Object.keys(e)[0];
          return { id: key, ...e[key] };
      }));
  }

  setPatrocinador(patrocinador: Patrocinador): void {
    this.patrocinador = patrocinador;
  }
}
