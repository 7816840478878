import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PatrocinadorComponent } from './patrocinador/patrocinador.component';
import { PatrocinadoresComponent } from './patrocinadores.component';


const routes: Routes = [
  {
    path: 'parceiros', children: [
      { path: '', component: PatrocinadoresComponent },
      { path: ':id', component: PatrocinadorComponent }
    ],
    data: {
      seo: {
        title: 'Parceiros & Patrocinadores',
        description: 'Parceiros e patrocinadores do Clube',
      }
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PatrocinadoresRoutingModule { }
