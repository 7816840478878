import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DiretoresComponent } from '../diretores/diretores.component';


const routes: Routes = [
  { path: 'direcao', component: DiretoresComponent, data: {
    seo: {
      title: 'Direção do Clube',
      description: 'Membros da direção do Jimny Clube CE'
    }
  }}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DirecaoRoutingModule { }
