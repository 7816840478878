import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DirecaoModule } from './direcao/direcao.module';
import { EventosModule } from './eventos/eventos.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { MenuComponent } from './menu/menu.component';
import { PatrocinadoresModule } from './patrocinadores/patrocinadores.module';
import { IconService } from './shared/icon.service';
import { SharedModule } from './shared/shared.module';
import { SobreComponent } from './sobre/sobre.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    MenuComponent,
    SobreComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    // AngularFireModule.initializeApp(environment.firebase),
    // AngularFireDatabaseModule,
    EventosModule,
    PatrocinadoresModule,
    DirecaoModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
  ],
  providers: [IconService],
  bootstrap: [AppComponent],
})
export class AppModule {

 }
