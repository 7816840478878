import { Pipe, PipeTransform } from '@angular/core';
import { Diretor } from '../shared/model';

@Pipe({
  name: 'filterDiretor'
})
export class FilterDiretorPipe implements PipeTransform {

  transform(value: Diretor[], ...args: any[]): any[] {
    if (!Array.isArray(value)) {
      return;
    }

    const [filter] = args;
    if (!isNaN(filter)) {
      return value.filter(item => item.geracoes.filter(geracao => geracao.cod === filter).length);
    }

    return value;
  }

}
