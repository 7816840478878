import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { PatrocinadorComponent } from './patrocinador/patrocinador.component';
import { PatrocinadoresRoutingModule } from './patrocinadores-routing.module';
import { PatrocinadoresComponent } from './patrocinadores.component';



@NgModule({
  declarations: [
    PatrocinadorComponent,
    PatrocinadoresComponent,
  ],
  imports: [
    CommonModule,
    PatrocinadoresRoutingModule,
    SharedModule
  ]
})
export class PatrocinadoresModule { }
