import { Component, Input, OnInit } from '@angular/core';
import { Diretor } from 'src/app/shared/model';

@Component({
  selector: 'app-card-diretor',
  templateUrl: './card-diretor.component.html',
  styleUrls: ['./card-diretor.component.scss']
})
export class CardDiretorComponent implements OnInit {

  @Input() diretor: Diretor;
  @Input() geracao: number;

  constructor() { }

  ngOnInit(): void {
  }

  cargo(): string {
    return this.diretor.geracoes?.filter(ger => ger.cod === this.geracao)[0]?.cargo;
  }

}
