import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'sanitize'})
export class ResourceSanitize implements PipeTransform {
    constructor(private domSanitizer: DomSanitizer) {}

    transform(resource: string): SafeHtml {
        if (resource) {
            return this.domSanitizer.bypassSecurityTrustResourceUrl(resource);
        }

        return this.domSanitizer.bypassSecurityTrustResourceUrl('');
    }
}
