import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { EventosService } from '../services/eventos.service';
import { Evento } from '../shared/model';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.scss']
})
export class EventosComponent implements OnInit, OnDestroy {

  eventos: Evento[] = [];
  eventoSelecionado: Evento;
  sub: Subscription;

  constructor(private service: EventosService) { }

  ngOnInit(): void {
    this.eventos = [];
    this.sub = this.service.getAll()
      .subscribe(data => {
        Object.keys(data).forEach(key => {
          const evento = {...data[key]};
          evento.data = moment(data[key].data).toDate();
          this.eventos.push(evento);
        });
      });
  }

  checkDisabled(evento: Evento): boolean {
    return evento?.data?.getTime() < new Date().getTime();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
