<app-titulo titulo="Eventos" subtitulo="do Clube"></app-titulo>
<div class="content">
    <div class="eventos">
        <div class="evento" *ngFor="let evento of eventos" [ngClass]="{ disabled: checkDisabled(evento) }">
            <div class="foto">
                <img [src]="evento.foto" alt="Foto do evento {{evento.nome}}">
            </div>
            <div class="titulo">{{evento.nome}}</div>
            <div class="data">{{evento.data | date: 'dd/MM/yyyy HH:mm:ss'}} - <mat-icon>location_on</mat-icon> {{ evento.local }}</div>
            <div class="descricao">{{evento.descricao}}</div>

            <div class="glue"></div>
            <div class="botoes">
                <button class="btn-primary" [disabled]="checkDisabled(evento)"
                    (click)="eventoSelecionado = evento">Participar</button>
            </div>
        </div>
    </div>
    <app-side-container [active]="eventoSelecionado" (cancel)="eventoSelecionado = null">
        <div class="inscricao">
            <h1>Evento</h1>
            <div class="titulo">{{eventoSelecionado?.nome}}</div>
            <div class="descricao">{{eventoSelecionado?.descricao}}</div>
            <iframe *ngIf="eventoSelecionado?.linkInscricao" [src]="eventoSelecionado?.linkInscricao | sanitize" frameborder="0" marginheight="0"
                marginwidth="0">Carregando…</iframe>

            <button id="cancelar" class="btn-primary" (click)="eventoSelecionado = null">Cancelar</button>
        </div>
    </app-side-container>
</div>