import { Component, OnInit } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { SobreService } from '../services/sobre.service';
import { MenuService } from '../shared/menu.service';
import { MenuItem } from '../shared/model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  menus: MenuItem[];
  urlContato = '';

  constructor(private menuService: MenuService,
    private sobreService: SobreService) {
    this.menus = menuService.menus;
  }

  ngOnInit(): void {
    this.sobreService.getContatoUrl()
    .pipe(
      take(1),
      map(url => {
        this.urlContato = url
      })
    ).subscribe();
  }

}
