import { Component, OnInit } from '@angular/core';
import { MenuService } from '../shared/menu.service';
import { MenuItem } from '../shared/model';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  menus: MenuItem[];

  constructor(menuService: MenuService) {
    this.menus = menuService.menus;
   }

  scrollTo(): void {
    if (window.screenTop === 0) {
      window.scrollTo({ top: 30000 });
    }
  }

}
