import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, forkJoin } from 'rxjs';
import { DirecaoService } from '../services/direcao.service';
import { Diretor } from '../shared/model';

@Component({
  selector: 'app-diretores',
  templateUrl: './diretores.component.html',
  styleUrls: ['./diretores.component.scss']
})
export class DiretoresComponent implements OnInit {

  diretores: Diretor[] = [];
  numbers: number[] = [];
  loading: boolean;
  geracaoAtual = 0;
  @Input() showAll: boolean;

  constructor(private service: DirecaoService) { }

  ngOnInit(): void {
    this.loading = true;
    
    const geracao$ = this.service.getGeracao()
    const getAll$ = this.service.getAll();

    const subs = combineLatest([geracao$, getAll$])
      .subscribe(([geracao, data]) => {
        this.geracaoAtual = geracao;
        this.numbers = [...Array(geracao + 1).keys()].reverse();

        this.loading = false;
        Object.keys(data).forEach(key => {
          const diretor = {
            id: key,
            ...data[key]
          };
          this.diretores.push(diretor);
        });
        subs.unsubscribe();
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }, err => {
        this.loading = false;
      });

    
  }

  descricao(cod: number): string {
    if (cod === 0) {
      return 'Fundadores';
    }
    if (cod === this.geracaoAtual) {
      return 'Geração Atual';
    }

    return `Geração ${cod}`;
  }

}
