import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class SobreService {

    constructor(private http: HttpClient) {}

    getContatoUrl(): Observable<string> {
      return this.http.get<string>('https://jimnyclubece-1734d.firebaseio.com/contatoUrl.json');
    }

    getSolicitarCartaoUrl(): Observable<string> {
      return this.http.get<string>('https://jimnyclubece-1734d.firebaseio.com/solicitarCartaoUrl.json');
    }
}