import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PatrocinadoresService } from 'src/app/services/patrocinadores.service';
import { SeoService } from 'src/app/services/seo.service';
import { Contato, Patrocinador } from 'src/app/shared/model';

@Component({
  selector: 'app-patrocinador',
  templateUrl: './patrocinador.component.html',
  styleUrls: ['./patrocinador.component.scss']
})
export class PatrocinadorComponent implements OnInit, OnDestroy {

  patrocinador: BehaviorSubject<Patrocinador> = new BehaviorSubject(null);
  routerSub: Subscription;
  dbSub: Subscription;
  loading: boolean;

  constructor(private seoService: SeoService,
              private router: Router,
              private patrocinadorService: PatrocinadoresService) {

    this.loading = true;
    this.patrocinador.subscribe(patroc => {
      seoService.setData({
        description: patroc?.nome,
        image: patroc?.logo
      });
    });

    this.routerSub = router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        const patrocinadorCod = e.url.split('/').slice(-1).pop();
        this.dbSub = patrocinadorService.getById(patrocinadorCod)
          .subscribe(v => {
            this.loading = false;
            this.patrocinador.next(v);
          });

      });
  }

  endereco(): string {
    return this.patrocinador.value?.endereco ?? 'Endereço não cadastrado';
  }

  contatos(): Contato[] {
    return this.patrocinador.value?.contatos;
  }

  exibirBarra(i: number): boolean {
    return i < this.contatos().length - 1;
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.routerSub?.unsubscribe();
    this.dbSub?.unsubscribe();
  }

}
