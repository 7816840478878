<app-home></app-home>
<section class="pt-100"></section>
<section class="pb-80">
    <div class="content flex">
        <app-titulo titulo="Sobre o" subtitulo="Jimny Clube CE"></app-titulo>
        <div class="descricao">
            <p>
                O <strong>Jimny Clube Ceará</strong> iniciou em 22/10/2017, quando os amigos <strong>Ives
                    Sampaio</strong> e
                <strong>Valdir Coelho</strong> decidiram juntar uma turma
                para realizarem eventos, trilhas, passeios. O grupo foi crescendo, foi criada a primeira diretoria para
                definir as
                diretrizes do grupo, tomar decisões
                e organizar o clube. Aos poucos o Jimny Clube Ceará cresceu, vários encontros foram marcados para que a
                turma se
                conhecesse, conversassem e, óbvio,
                falassem sobre o Suzuki Jimny.
            </p>
        </div>
</div>
</section>
<section class="pt-100 pb-80 parceiros">
    <div class="content flex column">
        <app-titulo titulo="Nosso" subtitulo="Cartão"></app-titulo>
        <div class="flex">
            <div class="descricao md-50 lg">
                <p>
                    Ao longo dos 3 anos do Jimny Clube, formamos várias parcerias que trouxessem benefícios ao clube. E
                    também que viabilizassem as trilhas e eventos.
                    O cartão do clube veio para solidificar ainda mais essa união. É através dele que conseguimos
                    mostrar
                    aos nossos patrocinadores
                    que estamos buscando o melhor para quem tem e para quem aceita.
                </p>

                <a [href]="urlCartao" mat-stroked-button target="_blank">Solicitar Cartão</a>
            </div>
            <div class="cartao" [ngClass]="{ 'active': isHovering }">
                <div class="cartao-container">
                    <div class="frente"></div>
                    <div class="costas"></div>
                </div>

                <button mat-stroked-button color="accent" (click)="hover()">
                    <mat-icon>touch_app</mat-icon>
                    Clique para rotacionar o cartão
                </button>
            </div>
        </div>
    </div>
</section>
<section class="pt-100 pb-80 projetos">
    <div class="content flex">
        <div class="item column">
            <mat-icon>event</mat-icon>
            <div class="number">25</div>
            <label>Eventos</label>
        </div>
        <div class="item column">
            <mat-icon>people</mat-icon>
            <div class="number">215</div>
            <label>Membros</label>
        </div>
        <div class="item column">
            <mat-icon>thumb_up</mat-icon>
            <div class="number">{{totalPatrocinadores}}</div>
            <label>Parceiros</label>
        </div>
        <div class="item column">
            <mat-icon>accessibility</mat-icon>
            <div class="number">5</div>
            <label>Instituições ajudadas</label>
        </div>
    </div>
</section>
<section class="contatos pt-100 pb-80">
    <div class="content flex">
        <app-titulo titulo="Faça" subtitulo="parte"></app-titulo>
        <div class="descricao">
            <p>
                Se você estiver de acordo com as nossas <strong><a [routerLink]="['/regras']">Regras</a>,</strong>
                e tiver interesse, entre em contato conosco e faça parte do clube que mais agrega no offroad.

                Lembrando que, para fazer parte, é necessário ser do Estado do Ceará e possuir Jimny ou Samurai.
            </p>
            <a [href]="urlContato" target="_blank"
                mat-flat-button color="primary" class="btn">Entrar em contato</a>
        </div>
    </div>
</section>