import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SobreComponent } from './sobre/sobre.component';


const routes: Routes = [
  { path: '', component: SobreComponent,
    data: {
      seo: {
        title: '',
        description: 'Página Principal',
      }
    }
  },
  { path: '', loadChildren: () => import('./regras/regras.module').then(m => m.RegrasModule) },
  { path: '', loadChildren: () => import('./direcao/direcao.module').then(m => m.DirecaoModule) },
  { path: '', loadChildren: () => import('./eventos/eventos.module').then(m => m.EventosModule) },
  { path: '', loadChildren: () => import('./patrocinadores/patrocinadores.module').then(m => m.PatrocinadoresModule)},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
