import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Diretor } from '../shared/model';

@Injectable({
  providedIn: 'root'
})
export class DirecaoService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<Diretor[]> {
    return this.http.get<Diretor[]>('https://jimnyclubece-1734d.firebaseio.com/direcao.json');
  }
  
  getGeracao(): Observable<number> {
    return this.http.get<number>('https://jimnyclubece-1734d.firebaseio.com/geracaoAtual.json');
  }
}
