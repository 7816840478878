import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SeoSocialShareData } from '../shared/model';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private readonly metaService: Meta,
    private readonly titleService: Title
  ) {
  }

  setData(data: SeoSocialShareData): void {
    const { title } = data;
    let { image } = data;
    if (image?.startsWith('data')) {
      image = this.convertDataUrlToBlob(image);
    }
    if (title) {
      this.setTitle(title);
    } else {
      this.setTitle();
    }
    this.metaService.updateTag({property: 'og:description', content: data.description});
    this.metaService.updateTag({property: 'og:image', content: image ?? '/assets/images/logo.png'});
  }

  private convertDataUrlToBlob(dataUrl): string {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return URL.createObjectURL(new Blob([u8arr], {type: mime}));
}

  private setTitle(ttl?: string): void {
    let title = `Jimny Clube CE :: ${ttl}`;
    if (!ttl) {
      title = 'Jimny Clube CE';
    }
    this.titleService.setTitle(title);
    if (title && title.length) {
      this.metaService.updateTag({name: 'twitter:title', content: title});
      this.metaService.updateTag({name: 'twitter:image:alt', content: title});
      this.metaService.updateTag({property: 'og:image:alt', content: title});
      this.metaService.updateTag({property: 'og:title', content: title});
      this.metaService.updateTag({name: 'title', content: title});
    } else {
      this.metaService.removeTag(`name='twitter:title'`);
      this.metaService.removeTag(`name='twitter:image:alt'`);
      this.metaService.removeTag(`property='og:image:alt'`);
      this.metaService.removeTag(`property='og:title'`);
      this.metaService.removeTag(`name='title'`);
    }
  }

}
