import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuItem } from './model';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  opened: BehaviorSubject<boolean> = new BehaviorSubject(false);
  menus: MenuItem[] = [
    { label: 'Home', path: '/' },
    { label: 'Eventos', path: '/eventos' },
    { label: 'Parceiros', path: '/parceiros' },
    { label: 'Direção', path: '/direcao' },
    { label: 'Regras', path: '/regras' },
  ];

  constructor() { }

  toggleMenu(): void {
    this.opened.next(!this.opened.getValue());
  }
}
