<div class="content">
    <footer>
        <div class="logo-descricao">
            <mat-icon svgIcon="logo"></mat-icon>
            <p>Jimny Clube CE é uma grande família offroad com valores conscientes e que pratica um offroad com
                consciência
                ecológica. Temos uma cultura saudável e agregadora dentro do clube.</p>
        </div>
        <div class="menus">
            <h1>Menu</h1>
            <div *ngFor="let menu of menus">
                <nav>
                    <a [routerLink]="[menu.path]">{{menu.label}}</a>
                </nav>
            </div>
        </div>
        <div class="redes">
            <h1>Redes sociais</h1>
            <a href="https://www.instagram.com/jimnyclube.ce">Instagram</a>
            <a href="https://www.facebook.com/jimnyclube">Facebook</a>
            <a [href]="urlContato" target="_blank">Whatsapp</a>
        </div>
    </footer>
</div>
<div class="footer">
    © Copyright 2020. <a href="https://www.instagram.com/gbr.camara">Gabriel Câmara Carvalho</a>
</div>