<app-loader [loading]="loading"></app-loader>
<div class="container" *ngIf="!loading">
    <div class="header">
        <div class="foto">
            <img *ngIf="patrocinador.value?.logo" [src]="patrocinador.value?.logo"
                alt="Logo de {{patrocinador.value?.nome}}">
        </div>

        <div class="dados-basicos">
            <div class="nome">{{patrocinador.value?.nome}} <span *ngIf="patrocinador.value?.categoria">({{ patrocinador.value.categoria }})</span></div>
            <div class="endereco">
                <mat-icon>location_on</mat-icon>
                {{endereco()}}
            </div>
            <div class="contatos">
                <mat-icon>phone</mat-icon>
                <div *ngIf="!contatos()?.length; else contatosz">Nenhum contato informado</div>
                <ng-template #contatosz>
                    <div class="lista-contatos">
                        <div *ngFor="let contato of contatos(); let i = index">
                            {{ contato.tipo }}: {{ contato.valor }} 
                            <span *ngIf="exibirBarra(i)"> /&nbsp;</span>
                        </div>
                    </div>
                </ng-template>
            </div>
            <div class="instagram" *ngIf="patrocinador.value?.instagram">
                <mat-icon svgIcon="instagram"></mat-icon><a href="https://www.instagram.com/{{patrocinador.value.instagram}}">@{{ patrocinador.value?.instagram }}</a>
            </div>
        </div>
    </div>

    <div class="abas">
        <mat-tab-group>
            <mat-tab label="Benefícios">
                <div class="content">
                    <strong>{{ patrocinador.value?.nome }}</strong> tem os benefícios abaixo para o clube:
                    <div class="beneficio" *ngFor="let beneficio of patrocinador.value?.beneficios">
                        <strong>Tipo do benefício:</strong> {{beneficio.nome}}<br/>
                        <strong>Descrição:</strong> {{beneficio.descricao}}
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Serviços">
                <div class="content">
                    Nenhum serviço cadastrado
                </div>
            </mat-tab>
            <mat-tab label="Produtos">
                <div class="content">
                    Nenhum produto cadastrado
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>