import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PatrocinadoresService } from '../services/patrocinadores.service';
import { Patrocinador } from '../shared/model';

@Component({
  selector: 'app-patrocinadores',
  templateUrl: './patrocinadores.component.html',
  styleUrls: ['./patrocinadores.component.scss'],
})
export class PatrocinadoresComponent implements OnInit, OnDestroy {

  public patrocinadores: BehaviorSubject<Patrocinador[]> = new BehaviorSubject([]);
  public selected: Patrocinador;
  public loading: boolean;
  public filter: string;
  public firebaseSub: Subscription;

  constructor(private service: PatrocinadoresService,
              private router: Router,
              private route: ActivatedRoute) { }

  public ngOnInit(): void {
    this.loading = true;
    this.firebaseSub = this.service.getAll().subscribe((e) => {
      this.loading = false;
      const patrocinadores = [];

      Object.keys(e).forEach((key) => {
        const patrocinador = {
          id: key,
          ...e[key],
        };

        patrocinadores.push(patrocinador);
        this.patrocinadores.next(patrocinadores);
      });
    }, (error) => {
      this.loading = false;
    });
  }

  public select(patrocinador: Patrocinador): void {
    if (this.selected === patrocinador) {
      this.selected = null;
      this.service.setPatrocinador(null);
      return;
    }
    this.selected = patrocinador;
    this.service.setPatrocinador(patrocinador);
    this.router.navigate([this.selected.id], { relativeTo: this.route });
  }

  public ngOnDestroy(): void {
    this.firebaseSub?.unsubscribe();
  }

}
