import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardDiretorComponent } from '../diretores/card-diretor/card-diretor.component';
import { DiretoresComponent } from '../diretores/diretores.component';
import { FilterDiretorPipe } from '../pipes/filter-diretor.pipe';
import { SharedModule } from '../shared/shared.module';
import { DirecaoRoutingModule } from './direcao-routing.module';



@NgModule({
  declarations: [
    FilterDiretorPipe,
    DiretoresComponent,
    CardDiretorComponent,
  ],
  imports: [
    CommonModule,
    DirecaoRoutingModule,
    SharedModule
  ]
})
export class DirecaoModule { }
