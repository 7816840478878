<app-titulo titulo="Direção" subtitulo="do Clube"></app-titulo>
<h4>
    Os membros da direção do Jimny Clube CE são responsáveis por tomar decisões em conjunto, fazer levantamento de
    trilhas
    angariar novos patrocinadores, promoções e eventos para o clube.
    Também são responsáveis por preservar as <a [routerLink]="['/regras']">Regras</a>, alertar e adicionar novos
    membros.
</h4>
<div class="container" *ngIf="diretores.length">
    <div class="cards" *ngFor="let number of numbers">
        <app-titulo [titulo]="descricao(number)"></app-titulo>
        <div class="card-container">
            <div class="card" *ngFor="let diretor of diretores | filterDiretor: number">
                <app-card-diretor [diretor]="diretor" [geracao]="number"></app-card-diretor>
            </div>
        </div>
    </div>
    <!-- <div class="demais-diretores" *ngIf="showAll"></div> -->
</div>
<app-loader [loading]="loading"></app-loader>