import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {

  transform(value: [], ...args: any[]): any[] {
    if (!Array.isArray(value)) {
      return;
    }

    const [attribute, filter] = args;

    if (filter) {
      // @ts-ignore
      return value.filter(item => item[attribute].toLowerCase().indexOf(filter.toLowerCase()) > -1);
    }

    return value;
  }

}
