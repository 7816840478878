import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { combineAll, map, take } from 'rxjs/operators';
import { PatrocinadoresService } from '../services/patrocinadores.service';
import { SobreService } from '../services/sobre.service';

@Component({
  selector: 'app-sobre',
  templateUrl: './sobre.component.html',
  styleUrls: ['./sobre.component.scss']
})
export class SobreComponent implements OnInit {
  totalPatrocinadores: number;
  isHovering: boolean;
  urlContato = '';
  urlCartao = '';

  constructor(private patrocinadoresService: PatrocinadoresService,
    private sobreService: SobreService) {
  }

  ngOnInit(): void {
    this.patrocinadoresService.total.subscribe(total => {
      this.totalPatrocinadores = total;
    });

    const solicitarCartao$ = this.sobreService.getSolicitarCartaoUrl();
    const contatoUrl$ = this.sobreService.getContatoUrl();
    combineLatest([solicitarCartao$, contatoUrl$])
      .pipe(
        take(1)
    ).subscribe(([cartaoUrl, contatoUrl]) => {
      this.urlCartao = cartaoUrl;
      this.urlContato = contatoUrl;
    });
  }

  hover(): void {
    this.isHovering = !this.isHovering;
  }

}
