import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IconService } from './shared/icon.service';
import { MenuService } from './shared/menu.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  openedMenu: boolean;

  constructor(private iconService: IconService,
              private menuService: MenuService,
              private router: Router) {
    this.menuService.opened.subscribe(toggled => this.openedMenu = toggled);
    this.router.events.subscribe(_ => {
       menuService.opened.next(false);
       window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }
}
